var THF = THF || {};
THF.Interface = THF.Interface || {};

(function($) {
    'use strict';

    THF.Interface.LeadPopUp = function(options) {
        var defaults = {
            appBody: 'body',
            helpButton: '.lead-pop-up-trigger',
            formModal: '.lead-capture-popup',
            formClose: '.lead-capture-popup .lead-capture-close',
            ctaButton: '.lead-capture-cta',
            thankYouConfirmation: '.lead-capture-popup .wrapper .thank-you-confirmation',
            initialFormContent: '.lead-capture-popup .wrapper .initial-form-content',
            popupTimer: 3000,
            currentPage: ''
        };

        options = $.extend(true, {}, defaults, options);

        var modalTimeout;
        function init() {
            var $helpButton = $(options.helpButton);
            var $formModal = $(options.formModal);
            var $formClose = $(options.formClose);
            var $ctaButton = $(options.ctaButton);
            var $thankYouConfirmation = $(options.thankYouConfirmation);
            var $initialFormContent = $(options.initialFormContent);
            var $body = $(options.appBody);
            var $form = $formModal.find('.lead-capture-form');
            
            var formSubmitted = false;
            
            if ($form.length) {
                modalTimeout = setTimeout(function() {
                    $formModal.addClass('active');
                    $body.toggleClass('no-scroll');
                    $helpButton.toggleClass('inactive');

                    DSTAPI.track('event', {
                        category: 'Lead Capture Popup',
                        action: 'Form After Timer',
                        label: 'Popup Timer'
                    });
                }, options.popupTimer);
    
                $helpButton.on("click", function() {
                    clearTimeout(modalTimeout);
                    $formModal.toggleClass('active');
                    $helpButton.toggleClass('inactive');
                    $body.toggleClass('no-scroll');

                    DSTAPI.track('event', {
                        category: 'Lead Capture Popup',
                        action: 'Help Button Clicked',
                        label: 'Help Trigger'
                    });
                });
    
                $formClose.on("click", function() {
                    $formModal.removeClass('active');
                    $helpButton.removeClass('inactive');
                    $body.removeClass('no-scroll');

                    var closeAction = formSubmitted ? 'Closed After Submission' : 'Closed';
                    DSTAPI.track('event', {
                        category: 'Lead Capture Popup',
                        action: closeAction,
                        label: 'Close'
                    });
                });

                $ctaButton.on('click', function () {
                    // Validate form before proceeding
                    if (!validateForm()) {
                        DSTAPI.track('event', {
                            category: 'Lead Capture Popup',
                            action: 'Form Submission Failed',
                            label: 'Submit Button'
                        });
                        return;
                    }
            
                    // Collect form data
                    var queryParams = {
                        firstName: $('#firstName').val().trim(),
                        lastName: $('#lastName').val().trim(),
                        email: $('#email').val().trim(),
                        isMember: $('#checkbox').is(':checked') ? "Y" : "N",
                        mobilePhone: $('#phoneType').val() === 'mobile' ? $('#phoneNumber').val().trim() : '',
                        homePhone: $('#phoneType').val() === 'home' ? $('#phoneNumber').val().trim() : '',
                        workPhone: $('#phoneType').val() === 'work' ? $('#phoneNumber').val().trim() : '',
                        currentPage: options.currentPage,
                        utm: THF.Interface.CampaignCapture.getCapturedQueryString()
                    };
            
                    // Send the form data as JSON
                    $.ajax({
                        url: '/api/v1/leadCaptureForm',
                        type: 'POST',
                        contentType: 'application/json',
                        data: JSON.stringify(queryParams),  
                        success: function (response) {
                            $('.thank-you-confirmation').addClass('active');
                            $('.initial-form-content').addClass('inactive');
                            formSubmitted = true;

                            DSTAPI.track('event', {
                                category: 'Lead Capture Popup',
                                action: 'Form Submission Passed',
                                label: 'Submit Button'
                            });
                        },
                        error: function (error) {
                            $('#form-error-msg').show();

                            DSTAPI.track('event', {
                                category: 'Lead Capture Popup',
                                action: 'Form Submission Failed',
                                label: 'Submit Button'
                            });
                        }
                    });
                });
    
                // Allow only numbers
                $('#phoneNumber').on('input', function() {
                    this.value = this.value.replace(/[^0-9]/g, ''); 
                });
            }
        }

        // Validate inputs dynamically while typing, excluding phone number
        dynamicValidation();

        function dynamicValidation() {
            var $inputs = $('.lead-capture-form input[required]');
            var $phoneType = $('#phoneType');
            var $phoneNumber = $('#phoneNumber');

            // Validate individual inputs on typing
            $inputs.each(function() {
                var $input = $(this);
                $input.on('input', function() {
                    validateField($input);
                    if ($input.attr('id') === 'phoneNumber') {
                        validatePhoneField(); 
                    }
                });
            });

            // Validate phone-related fields separately as user types or selects phone type
            $phoneType.add($phoneNumber).on('input change', function () {
                validatePhoneField();
            });
        }

        function validateField($input) {
            var isValid = true;
            var $errorMessage = $input.next('.error-message');

            // Check if the field is empty
            if (!$input.val().trim()) {
                isValid = false;
                $errorMessage.show();
                $input.addClass('input-error');
            } else {
                $errorMessage.hide();
                $input.removeClass('input-error');
            }

            // Validate max length for first name and last name
            if ($input.attr('id') === 'firstName' && $input.val().length > 16) {
                isValid = false;
                $errorMessage.text('First name should be at most 16 characters long.').show();
                $input.addClass('input-error');
            } else if ($input.attr('id') === 'lastName' && $input.val().length > 25) {
                isValid = false;
                $errorMessage.text('Last name should be at most 25 characters long.').show();
                $input.addClass('input-error');
            }

            // Additional email validation
            if ($input.attr('id') === 'email') {
                var emailPattern = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z_])*@(([0-9a-zA-Z])+([-\w]*[0-9a-zA-Z])*\.)+[a-zA-Z]{2,9})$/;
                if ($input.val().trim() && !emailPattern.test($input.val().trim())) {
                    isValid = false;
                    $errorMessage.text('Please enter a valid email address.').show();
                    $input.addClass('input-error');
                }
            }

            return isValid;
        }

        function validatePhoneField() {
            var $phoneType = $('#phoneType');
            var $phoneNumber = $('#phoneNumber');
            var $phoneErrorMessage = $phoneType.closest('.input-container').find('.error-message');
            var phoneValue = $phoneNumber.val().trim();
            var phonePattern = /^[0-9]+$/;

            // Check if the phone number is empty if phone type is selected
            if (!phoneValue) {
                $phoneErrorMessage.text('Please enter a phone number.').show();
                $phoneNumber.addClass('input-error');
                return false;
            }

            // Check if the phone number consists of numbers only
            if (!phonePattern.test(phoneValue)) {
                $phoneErrorMessage.text('Phone number can only contain numbers.').show();
                $phoneNumber.addClass('input-error');
                return false;
            }

            // Check if the phone number is 10 digits
            if (phoneValue.length > 10) {
                $phoneErrorMessage.text('Phone number cannot be longer than 10 digits.').show();
                $phoneNumber.addClass('input-error');
            }

            if ($phoneType.val() === 'mobile') {
                // Validate mobile numbers (must start with '04' and be exactly 10 digits long)
                if (!/^04\d{8}$/.test(phoneValue)) {
                    $phoneErrorMessage.text('Please enter a valid mobile number (starting with 04 and exactly 10 digits).').show();
                    $phoneNumber.addClass('input-error');
                    return false;
                }
            } else if ($phoneType.val() === 'home' || $phoneType.val() === 'work') {
                // Validate home/work numbers (must start with 02, 03, 07, or 08 and be exactly 10 digits long)
                if (!/^(02|03|07|08)\d{8}$/.test(phoneValue)) {
                    $phoneErrorMessage.text('Please enter a valid '+ $phoneType.val() +' phone number (starting with area code, numbers only, and must be 10 digits)').show();
                    $phoneNumber.addClass('input-error');
                    return false;
                }
            }

            $phoneErrorMessage.hide();
            $phoneNumber.removeClass('input-error');
            return true;
        }

        function validateForm() {
            var isValid = true;
            var $inputs = $('.lead-capture-form input[required]');

            // Validate required inputs
            $inputs.each(function() {
                if (!validateField($(this))) {
                    isValid = false;
                }
            });

            // Validate phone fields only on form submission
            if (!validatePhoneField()) {
                isValid = false;
            }

            return isValid;
        }

        init();

        return {};
    };

})(jQuery);
